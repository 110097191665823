/***
 * @description 二期相关接口
 */
import axios from '@/lib/axios.js';
import localstorage from '@/lib/localstorage';
import { localStorageKey } from '../localStorageKey';
import {
    accountList,
    allocationCUrl,
    allocationMUrl,
    applyCheckRefundUrl,
    applyInfoListUrl,
    delApplyInfoUrl,
    delFeedbackUrl,
    delNewsUrl,
    feedbackUrl,
    goodsDelClass,
    goodsDelParameter,
    goodsListClass,
    goodsListUrl,
    goodsParameterUrl,
    goodsSaveClass,
    goodsSaveParameter,
    goodsSaveStatusUrl,
    goodsSaveUrl,
    goodsSerListUrl,
    goodsUpdateClassUrl,
    goodsViewUrl,
    merchantCreateAccountUrl,
    merchantSaveAccountUrl,
    merchantUrl,
    newsUrl,
    orderApplyListUrl,
    orderIndexUrl,
    orderRefundGoodUrl,
    orderRefundListUrl,
    orderSaveSettingUrl,
    orderSettingUrl,
    orderSfcListUrl,
    orderSfcModUrl,
    orderSfcUrl,
    orderStatUrl,
    orderViewUrl,
    saleStatUrl,
    saveApplyInfoUrl,
    saveNewsUrl,
    savePwdUrl,
    saveSpecUrl,
    searchSpecUrl,
    userStatUrl,
    userViewUrl,
    wxPaySetUrl,
    wxPaySetViewUrl,
    mappingListUrl,
    saveMappingUrl,
    delMappingUrl,
    homeGdListUrl,
    saveHomeGdUrl,
    delHomeGdUrl,
    discountCodeListUrl,
    discountCodeSaveUrl,
    discountCodeDelUrl,
    getWdLogs,
    incExpLogUrl1,
    paperReportUrl 
    
} from '@/lib/api';

function devUrl(_url) {
    let url = _url;
    // console.log('process.env.NODE_ENV=', process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'development') {
        url = '/api' + _url;
    }
    console.log('devUrl url = ', url, url.length);
    return url;
}

function parseParam(params = {}) {
    let _params = structuredClone(params);
    let token = localstorage.get(localStorageKey.ACCESS_TOKEN_QILANG);
    if (!!token) {
        _params.token = token;
    }
    return _params;
}

function openExportUrl(_url, _params) {
    let baseUrl = '';
    let url = devUrl(_url);
    let params = parseParam(_params);
    let field = [];
    for (let key in params) {
        field.push(`${key}=${params[key]}`);
    }
    window.open(baseUrl + url + '?' + field.join('&'));
}

//商品分类列表
export function reqGoodsListClass(param = {}) {
    return axios.postBody(devUrl(goodsListClass), parseParam(param));
}

//创建更新分类
export function reqGoodsSaveClass(param = {}) {
    return axios.postBody(devUrl(goodsSaveClass), parseParam(param));
}

//删除商品分类
export function reqGoodsDelClass(param = {}) {
    return axios.postBody(devUrl(goodsDelClass), parseParam(param));
}

//添加规格
export function reqSaveSpec(param = {}) {
    return axios.postBody(devUrl(saveSpecUrl), parseParam(param));
}

//查询规格
export function reqSearchSpec(param = {}) {
    return axios.postBody(devUrl(searchSpecUrl), parseParam(param));
}

//商品参数列表
export function reqGoodsParameter(param = {}) {
    return axios.postBody(devUrl(goodsParameterUrl), parseParam(param));
}

//添加更新商品参数
export function reqGoodsSaveParameter(param = {}) {
    return axios.postBody(devUrl(goodsSaveParameter), parseParam(param));
}

//删除商品参数
export function reqGoodsDelParameter(param = {}) {
    return axios.postBody(devUrl(goodsDelParameter), parseParam(param));
}

//添加修改商品
export function reqGoodsSave(param = {}) {
    return axios.postBody(devUrl(goodsSaveUrl), parseParam(param));
}
//商品列表
export function reqGoodsList(param = {}) {
    return axios.postBody(devUrl(goodsListUrl), parseParam(param));
}

//修改商品分类
export function reqGoodsUpdateClass(param = {}) {
    return axios.postBody(devUrl(goodsUpdateClassUrl), parseParam(param));
}
//上下架商品
export function reqGoodsSaveStatus(param = {}) {
    return axios.postBody(devUrl(goodsSaveStatusUrl), parseParam(param));
}
//商品详情
export function reqGoodsView(param = {}) {
    return axios.postBody(devUrl(goodsViewUrl), parseParam(param));
}

//反馈列表
export function reqFeedback(params = {}) {
    return axios.postBody(devUrl(feedbackUrl), parseParam(params));
}
//反馈删除
export function reqDelFeedback(params = {}) {
    return axios.postBody(devUrl(delFeedbackUrl), parseParam(params));
}

//资讯列表
export function reqNews(params = {}) {
    return axios.postBody(devUrl(newsUrl), parseParam(params));
}
//创建更新资讯
export function reqSaveNews(params = {}) {
    return axios.postBody(devUrl(saveNewsUrl), parseParam(params));
}
//创建更新资讯
export function reqDelNews(params = {}) {
    return axios.postBody(devUrl(delNewsUrl), parseParam(params));
}

//修改账号密码
export function reqSavePwd(params = {}) {
    return axios.postBody(devUrl(savePwdUrl), parseParam(params));
}

//服务商列表
export function reqMerchant(params = {}) {
    return axios.postBody(devUrl(merchantUrl), parseParam(params));
}
//创建服务商账号
export function reqMerchantCreateAccount(params = {}) {
    return axios.postBody(devUrl(merchantCreateAccountUrl), parseParam(params));
}
//修改服务商信息
export function reqMerchantSaveAccount(params = {}) {
    return axios.postBody(devUrl(merchantSaveAccountUrl), parseParam(params));
}
export const groupIdDef = {
    admin: '2', //管理员
    op: '3', //运营
    cs: '4', //客服
    qa: '5', //检测员
    sp: '6', //服务商
};
//获取指定角色下的账号列表
export function reqAccountByGroupId(groupId) {
    return axios.postBody(
        devUrl(accountList),
        parseParam({
            pageNums: 1,
            pageSize: 200,
            groupId,
        })
    );
}

//分配服务商
export function reqAllocationM(params) {
    return axios.postBody(devUrl(allocationMUrl), parseParam(params));
}
//分配检测人员
export function reqAllocationC(params) {
    return axios.postBody(devUrl(allocationCUrl), parseParam(params));
}

//用户信息详情
export function reqUserView(params) {
    return axios.postBody(devUrl(userViewUrl), parseParam(params));
}

//微信支付配置详情
export function reqWxPaySetView(params) {
    return axios.postBody(devUrl(wxPaySetViewUrl), parseParam(params));
}
//微信支付设置
export function reqWxPaySet(params) {
    return axios.postBody(devUrl(wxPaySetUrl), parseParam(params));
}

//售后原因列表
export function reqApplyInfoList(params) {
    return axios.postBody(devUrl(applyInfoListUrl), parseParam(params));
}
//新建更新售后原因
export function reqSaveApplyInfo(params) {
    return axios.postBody(devUrl(saveApplyInfoUrl), parseParam(params));
}
//删除售后原因
export function reqDelApplyInfo(params) {
    return axios.postBody(devUrl(delApplyInfoUrl), parseParam(params));
}

//商品订单列表
export function reqOrderIndex(params) {
    return axios.postBody(devUrl(orderIndexUrl), parseParam(params));
}

//商品订单-导出
export function reqOrderIndexExport(params) {
    openExportUrl(orderIndexUrl, params);
}
//导出问卷调查
export function paperReportExport(params) {
    openExportUrl(paperReportUrl, params);
}

//导出佣金明细
export function yjmxExport(params) {
    openExportUrl(incExpLogUrl1, params);
}
//商品订单详情
export function reqOrderView(params) {
    return axios.postBody(devUrl(orderViewUrl), parseParam(params));
}
//物流信息
export function reqOrderSfcList(params) {
    return axios.postBody(devUrl(orderSfcListUrl), parseParam(params));
}
//物流服务商
export function reqOrderSfcMod(params) {
    return axios.postBody(devUrl(orderSfcModUrl), parseParam(params));
}
//订单发货
export function reqOrderSfc(params) {
    return axios.postBody(devUrl(orderSfcUrl), parseParam(params));
}
//通用设置配置
export function reqOrderSetting(params) {
    return axios.postBody(devUrl(orderSettingUrl), parseParam(params));
}
//通用设置配置-保存
export function reqOrderSaveSetting(params) {
    return axios.postBody(devUrl(orderSaveSettingUrl), parseParam(params));
}
//售后订单列表
export function reqOrderApplyList(params) {
    return axios.postBody(devUrl(orderApplyListUrl), parseParam(params));
}
//售后订单列表-导出
export function reqOrderApplyExport(params) {
    openExportUrl(orderApplyListUrl, params);
}
//佣金-导出
export function yjExport(params) {
    openExportUrl(getWdLogs, params);
}
//售后审核退款
export function reqCheckRefund(params) {
    return axios.postBody(devUrl(applyCheckRefundUrl), parseParam(params));
}
//退款记录
export function reqOrderRefundList(params) {
    return axios.postBody(devUrl(orderRefundListUrl), parseParam(params));
}
//退款记录-导出
export function reqOrderRefundExport(params) {
    openExportUrl(orderRefundListUrl, params);
}
//退款记录分析
export function reqOrderRefundGood(params) {
    return axios.postBody(devUrl(orderRefundGoodUrl), parseParam(params));
}

//销售统计
export function reqSalesStat(params) {
    return axios.postBody(devUrl(saleStatUrl), parseParam(params));
}
//会员统计
export function reqUserStat(params) {
    return axios.postBody(devUrl(userStatUrl), parseParam(params));
}
//订单统计
export function reqOrderStat(params) {
    return axios.postBody(devUrl(orderStatUrl), parseParam(params));
}

//商品分类属性
export function reqGoodsSerList(params) {
    return axios.postBody(devUrl(goodsSerListUrl), parseParam(params));
}

//首页分类配置列表
export function reqMappingList(params) {
    return axios.postBody(devUrl(mappingListUrl), parseParam(params));
}
//创建更新首页分类配置
export function reqSaveMapping(params) {
    return axios.postBody(devUrl(saveMappingUrl), parseParam(params));
}
//删除首页分类配置
export function reqDelMapping(params) {
    return axios.postBody(devUrl(delMappingUrl), parseParam(params));
}

//首页广告配置
export function reqHomeGdList(params) {
    return axios.postBody(devUrl(homeGdListUrl), parseParam(params));
}
//创建更新首页广告
export function reqSaveHomeGd(params) {
    return axios.postBody(devUrl(saveHomeGdUrl), parseParam(params));
}
//删除首页广告
export function reqDelHomeGd(params) {
    return axios.postBody(devUrl(delHomeGdUrl), parseParam(params));
}

//优惠码列表
export function reqDiscountCodeList(params) {
    return axios.postBody(devUrl(discountCodeListUrl), parseParam(params));
}
//创建更新优惠码
export function reqDiscountCodeSave(params) {
    return axios.postBody(devUrl(discountCodeSaveUrl), parseParam(params));
}
//删除优惠码
export function reqDiscountCodeDel(params) {
    return axios.postBody(devUrl(discountCodeDelUrl), parseParam(params));
}
