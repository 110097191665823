export default {
    data() {
        return {
            params: {
                pageNums: 1, //--第几页
                pageSize: 10, //每页条数
            },
            totalPageNum: 0,
        };
    },
    methods: {
        getExportUrl(reqUrl) {
            let hostUrl = '//www.i-lz.cn';
            let appid = localStorage.getItem('h5Appid') || 'pPcRTzwscCEaPZUf';
            let aid = '-';
            let token = localStorage.getItem('subToken');
            let url = `${hostUrl}/${reqUrl}?aid=${aid}&token=${token}&appid=${appid}&xls=1`;
            if (!!this.params.content && !!this.params.key) {
                url += `&key=${this.params.key}&content=${this.params.content}`;
            }
            return url;
        },

        onDateChange(evt, item) {
            let _evt = evt || [];
            if (_evt.length > 0 && _evt[0].length <= 0) {
                _evt = [];
            }
            this.params[item.bind] = _evt;
        },

        currentChange(current) {
            this.params.pageNums = current;

            hm.isFunction(this.getList) && this.getList();
        },

        sizeChange(pageSize) {
            this.params.pageNums = 1;
            this.params.pageSize = pageSize;
            hm.isFunction(this.getList) && this.getList();
        },

        filterParams() {
            for (let k in this.params) {
                let val = this.params[k] + '';
                if (!(!!val && !!val.trim()) || val === 'undefined' || val === 'null') {
                    delete this.params[k];
                }
            }
        },

        injectSearchField(params) {
            this.params.pageNums = 1;
            if (params && params.isCustom) {
                delete params.isCustom;
                this.params = Object.assign({}, this.params, params);
            } else {
                this.params.pageNums = 1;
            }
            this.filterParams();
        },

        onExport(params) {
            this.injectSearchField(params);
            hm.isFunction(this.getExport) && this.getExport();
        },

        onSearch(params) {
            this.injectSearchField(params);
            hm.isFunction(this.getList) && this.getList();
        },

        reset() {
            this.params = {
                pageNums: 1,
                pageSize: this.params.pageSize || 20,
            };
            this.filterParams();
            hm.isFunction(this.getList) && this.getList();
        },
    },
};
