<template functional>
    <div class="relative height_100_percent flex column align_start">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'searchBody',
    functional: true,
};
</script>
