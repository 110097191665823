<template>
    <Cascader class="width_300" change-on-select filterable :data="cityData" :transfer="true" v-model="cityIds" @on-change="onChange"></Cascader>
</template>
<script>
import { useCascaderAreaData } from '@/lib/city.data';
export default {
    name: 'city',
    props: ['value'],

    data() {
        return {
            cityData: [],
            cityIds: [],
        };
    },
    watch: {
        value(newValue) {
            this.cityIds = newValue;
        },
    },
    mounted() {
        let cityList = useCascaderAreaData();
        (cityList || []).forEach(info => {
            info.label = info.text;
            let children = info.children || [];
            children.length > 0
                ? children.forEach(secInfo => {
                      secInfo.label = secInfo.text;
                      let secChildren = secInfo.children || [];
                      secChildren.length > 0
                          ? secChildren.forEach(thirdInfo => {
                                thirdInfo.label = thirdInfo.text;
                                delete thirdInfo.children;
                            })
                          : delete secChildren.children;
                  })
                : delete info.children;
        });
        this.cityData = cityList;
    },
    methods: {
        onChange(evt) {
            // console.log('evt', evt);
            this.$emit('input', evt || []);
        },
    },
};
</script>
