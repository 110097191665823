<template>
    <searchBody>
        <search class="searchbar" :show-create="true" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onEdit()"></search>
        <Card class="table_container" ref="table_container">
            <Table :key="tableKey" stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ row.status == 1 ? '启用' : '禁用' }}</span>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <!-- <Button type="success" size="small" ghost @click="onView(row)">详情</Button> -->
                        <Button type="primary" size="small" ghost @click="onEdit(row)">编辑</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqMerchant } from '@/lib/request/auth2';
import { consoleJson, isNotNullObject } from '@/lib/util';

export default {
    name: 'serviceProviderList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            searchs: [{ id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'name' }],
            tableKey: 'tableKey',
            dataColumn: [
                { key: 'account', title: '商家账号' },
                { key: 'name', title: '服务商名称', minWidth: 150 },
                { key: 'address', title: '公司地址', minWidth: 200 },
                { key: 'userName', title: '联系人' },
                { key: 'userPhone', title: '手机号' },
                // { key: 'receiveNum', title: '接单情况（次）' },
                { slot: 'status', title: '状态' },
                { key: 'ctime', title: '创建时间' },
                { slot: 'manager', title: '操作' },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        //获取列表数据
        getList() {
            this.showAjaxLoading();
            reqMerchant(this.params)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新建/编辑
        onEdit(_info = {}) {
            let info = window.structuredClone(_info);
            if (isNotNullObject(info)) {
                info.userTel = info.userPhone;
                info.address_code = !!info.address_code ? info.address_code.split(',') : [];
                info.address_note = !!info.address ? info.address.split(';')[1] : null;
                delete info.userPhone;
            } else {
                info.create = 1;
            }
            this.goName('serviceProviderAdd', info);
        },

        //详情
        onView(info = {}) {
            let { id } = info || {};
            this.goUrl('/serviceProviderView', { id });
        },
    },
};
</script>
<style lang="less" scoped>
.table_container {
    & .ivu-switch span {
        font-size: revert;
    }
}
</style>
