<template>
    <Card class="width_100_percent table-search" :bordered="true">
        <Form onsubmit="return false" :model="params" :key="formKey" :label-position="labelPosition" class="card-search-form" :label-width="80" :style="{ maxWidth: formMaxWidth }" :inline="inline">
            <template v-if="searchs.length === 0">
                <FormItem class="searchFormItem">
                    <Input v-model.trim="params.kw" class="searchInput" placeholder="请输入关键词" clearable prefix="ios-search-outline" @on-enter="onSearch" />
                </FormItem>
            </template>
            <template v-else>
                <FormItem v-for="item in searchs" :key="item.id" :label="item.label">
                    <Input
                        v-if="item.type === 'input'"
                        v-model.trim="params[item.bind]"
                        :placeholder="item.placeholder || '请输入关键词'"
                        clearable
                        :prefix="item.icon || 'ios-search-outline'"
                        @keyup.enter="onSearch"
                        @keyup.enter.native="onSearch"
                    ></Input>

                    <Select
                        :style="{ width: (item.width || 200) + 'px' }"
                        v-if="item.type === 'select'"
                        v-model="params[item.bind]"
                        clearable
                        :multiple="item.multiple"
                        :filterable="item.filterable || false"
                        :remote-method="item.remoteMethod"
                        :loading="item.selectLoading || false"
                        :placeholder="item.placeholder || '请选择'"
                    >
                        <Option
                            v-for="iten in item.list"
                            :key="iten[item.selectOptions ? item.selectOptions.key : 'id']"
                            :label="iten[item.selectOptions ? item.selectOptions.label : 'title']"
                            :value="iten[item.selectOptions ? item.selectOptions.value : 'id']"
                        ></Option>
                    </Select>
                    <DatePicker
                        :transfer="true"
                        v-if="item.type === 'datetimerange'"
                        v-model="params[item.bind]"
                        :clearable="item.clearable"
                        :editable="item.editable"
                        split-panels
                        type="datetimerange"
                        :placeholder="item.placeholder || '请选择'"
                        format="yyyy-MM-dd HH:mm:ss"
                        @on-change="evt => item.onChange(evt, item)"
                    ></DatePicker>

                    <DatePicker
                        :clearable="item.clearable"
                        :editable="item.editable"
                        format="yyyy-MM-dd"
                        @on-change="evt => item.onChange(evt, item)"
                        :transfer="true"
                        v-if="item.type === 'date'"
                        :placeholder="item.placeholder || '请选择'"
                        style="width: 200px"
                    ></DatePicker>

                    <DatePicker
                        :transfer="true"
                        v-if="item.type === 'daterange'"
                        :value="item.value"
                        :clearable="item.clearable"
                        :editable="item.editable"
                        :options="item.options"
                        split-panels
                        format="yyyy-MM-dd"
                        type="daterange"
                        placement="bottom-end"
                        :placeholder="item.placeholder || '请选择'"
                        style="width: 200px"
                        @on-change="evt => item.onChange(evt, item)"
                    ></DatePicker>

                    <Cascader v-if="item.type === 'cascader'" :transfer="true" :data="item.list" trigger="hover" v-model="params[item.bind]" :placeholder="item.placeholder || '请选择'"></Cascader>

                    <city v-model="params[item.bind]" v-if="item.type == 'city'"></city>

                    <slot v-if="item.slot" :name="item.bind" />
                </FormItem>
            </template>
            <FormItem :class="searchs.length === 0 && 'searchFormItem'">
                <div class="btnCtrl">
                    <Button type="primary" @click="onSearch">搜索</Button>
                    <Button @click="reset">重置</Button>
                    <Button v-if="showCreate" type="success" @click="onCreate">{{ createText || '新建' }}</Button>
                    <Button v-if="showExport" type="primary" @click="onExport">导出</Button>
                    <Button v-if="showDeleteAll" type="error" @click="onDeleteAll">删除所有用户数据</Button>
                    <Button v-if="showBack" type="warning" class="ml_auto" @click="onBack">返回</Button>
                </div>
            </FormItem>
            <div style="flex: 1">
                <slot></slot>
            </div>
        </Form>
        <Spin size="large" fix v-show="loading"></Spin>
    </Card>
</template>
<script>
import city from '@/components/city';

export default {
    name: 'tableSearch',
    components: {
        city,
    },
    props: {
        searchs: {
            type: Array,
            default: () => [],
        },
        labelWidth: {
            type: Number,
            default: 60,
        },

        labelPosition: {
            type: String,
            default: 'right',
        },

        formMaxWidth: {
            type: String,
            default: '100%',
        },

        inline: {
            type: Boolean,
            default: true,
        },

        showCreate: {
            type: Boolean,
            default: false,
        },

        createText: {
            type: String,
            default: null,
        },

        showExport: {
            type: Boolean,
            default: false,
        },
        showBack: {
            type: Boolean,
            default: false,
        },

        showDeleteAll: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formKey: 'formKey',
            params: {},
        };
    },
    created() {
        this.getParamsInStorage();
    },
    watch: {
        searchs: {
            handler: function (newVal, oldVal) {
                newVal.forEach(item => {
                    if (item.value) {
                        console.log('search', item.value);
                        this.params[item.bind] = item.value - 0;
                    }
                });
            },
            deep: true,
        },
    },

    methods: {
        onSearch() {
            this.params.isCustom = true;
            this.$emit('search', this.params);
        },

        reset() {
            this.params = {};
            this.formKey = Math.random().toString(16).substring(2);
            this.$emit('reset');
        },

        onCreate() {
            this.$emit('create');
        },

        onExport() {
            this.params.isCustom = true;
            this.$emit('export', this.params);
        },

        onDeleteAll() {
            this.$emit('deleteall');
        },

        onBack() {
            this.$emit('back');
        },
    },
};
</script>
<style lang="less" scoped>
.table-search .ivu-form-item {
    margin-bottom: 0 !important;
    width: auto !important;
}
.table-search .searchFormItem .ivu-form-item-content {
    margin-left: 0 !important;
}
.table-search {
    margin: 20px 0;
    padding: 0;
}
.table-search .card-search-form {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 0;
}
input[readOnly] {
    background: #fdfdfd !important;
    cursor: pointer !important;
}
.table-search .btnCtrl {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.card-title-text {
    display: inline-block;
    margin: 0 0 0 10px;
}
.searchFormItem {
    width: 50%;
    max-width: 750px;
    margin-right: 15px;
}
@media (max-width: 768px) {
    .table-search .card-search-form {
        flex-wrap: wrap;
    }
    .table-search .btnCtrl {
        gap: 5px;
    }
}
</style>
